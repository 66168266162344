import { Routes } from "@blitzjs/next"
import { invalidateQuery, useMutation, useQuery } from "@blitzjs/rpc"
import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core"
import {
  SortableContext,
  arrayMove,
  rectSortingStrategy,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable"
import styled from "@emotion/styled"
import * as AlertDialog from "@radix-ui/react-alert-dialog"
import * as Dialog from "@radix-ui/react-dialog"
import * as DropdownMenu from "@radix-ui/react-dropdown-menu"
import { FaCaretDown } from "@react-icons/all-files/fa/FaCaretDown"
import { FaTimes } from "@react-icons/all-files/fa/FaTimes"
import { TbArrowsMove } from "@react-icons/all-files/tb/TbArrowsMove"
import { TbEdit } from "@react-icons/all-files/tb/TbEdit"
import { TbLayoutGrid } from "@react-icons/all-files/tb/TbLayoutGrid"
import { TbLayoutList } from "@react-icons/all-files/tb/TbLayoutList"
import { TbPlus } from "@react-icons/all-files/tb/TbPlus"
import { TbShare } from "@react-icons/all-files/tb/TbShare"
import { TbTrash } from "@react-icons/all-files/tb/TbTrash"
import { TbPlayerPlay } from "@react-icons/all-files/tb/TbPlayerPlay"
import { TbArrowRight } from "@react-icons/all-files/tb/TbArrowRight"
import { Value } from "@stringtale/react/dist"
import { RichText } from "app/admin/components/RichText"
import ToggleButtons from "app/content-subjects/components/ToggleButtons"
import { useCurrentGroup } from "app/core/hooks/useCurrentGroup"
import { useCurrentOrganization } from "app/core/hooks/useCurrentOrganization"
import { GetAllGradesResult } from "app/grades/queries/getAllGrades"
import { MAX_KIDS_LIST } from "app/kids/consts"
import useSortLessons from "app/lessons/hooks/useSortLessons"
import PlaylistItem from "app/playlists/components/PlaylistItem"
import PlaylistTileLesson from "app/playlists/components/PlaylistTileLesson"
import { displayStateAtom, isPlayingAtom } from "app/radio/state"
import ShareModal from "app/share/components/ShareModal"
import useToast from "app/toasts/hooks/useToast"
import { ContentSubjectType } from "db"
import { AnimatePresence } from "framer-motion"
import { useAtom, useAtomValue } from "jotai"
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react"
import {
  DARK_BLUE_COLOR,
  GREEN_COLOR,
  LIGHT_GREY_COLOR,
  ORANGE_COLOR,
} from "theme/colors"
import { PX14, PX24, PX28 } from "theme/sizes"
import { MODAL_BOX_SHADOW } from "theme/styles"
import AlertModal from "ui/AlertModal"
import ContextMenu from "ui/ContextMenu"
import Group from "ui/Group"
import Hr from "ui/Hr"
import * as Modal from "ui/Modal"
import RoundedButtonBase from "ui/RoundedButton"
import * as ScrollArea from "ui/ScrollArea"
import Stack from "ui/Stack"
import Text from "ui/Text"
import TextButton from "ui/TextButton"
import Title from "ui/Title"
import toggleArray from "utils/global/toggleArray"
import { usePlay } from "../../radio/components/PlayerContext"
import { RADIUS, SORT_OPTIONS, SORT_OPTIONS_DISPLAY } from "../consts"
import batchDeleteUserPlaylist from "../mutations/batchDeleteUserPlaylist"
import deleteUserListItem from "../mutations/deleteUserListItem"
import deleteUserPlaylist from "../mutations/deleteUserPlaylist"
import updateUserPlaylist from "../mutations/updateUserPlaylist"
import updateUserPlaylistDisplayType from "../mutations/updateUserPlaylistDisplayType"
import updateUserPlaylistOrder from "../mutations/updateUserPlaylistOrder"
import getCanWriteUserPlaylist from "../queries/getCanWriteUserPlaylist"
import getGroupedUserPlaylists from "../queries/getGroupedUserPlaylists"
import getUserList, { GetUserListResult } from "../queries/getUserList"
import { multiSelectAtom } from "../state"
import getIsMigrationVisible from "../utils/getIsMigrationVisible"
import getListTypeCanAdd from "../utils/getListTypeCanAdd"
import DuplicatePlaylistModal from "./DuplicatePlaylistModal"
import Grades from "./Grades"
import PlaylistBatchUpdateModal from "./PlaylistBatchUpdateModal"
import PlaylistModalEmptyState from "./PlaylistModalEmptyState"
import PlaylistModalHeader from "./PlaylistModalHeader"
import PlaylistSortField from "./PlaylistSortField"
import { UserPlaylistTypeOwner } from "./UserPlaylistType"
import getBulletinBoardItems from "app/bulletinboard/queries/getBulletinBoardItems"
import { useRouter } from "next/router"
import {
  AddLessonToListModalUncontrolled,
  AddToPlaylistLesson,
} from "./AddLessonToListModal"
import getOrganizatorRole from "app/auth/queries/getOrganizatorRole"

// Stack grow overflow="hidden"
const Root = styled(Stack)`
  position: relative;
  flex: 1;
  overflow: hidden;
  display: flex;
`

const Content = styled.div`
  border-bottom-right-radius: ${RADIUS};
  border-top-right-radius: ${RADIUS};
  padding: 0 32px;
  overflow: auto;
  flex: 1;
`

const ContentPadding = styled.div`
  padding: 40px 0;
`

const UserListPadding = styled.div`
  padding: 0 0 120px 0;
`

const Filter = styled.div`
  padding: 16px 0;
  margin: 0 32px;
  border-bottom: 2px solid ${LIGHT_GREY_COLOR};
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  font-size: ${PX14};
`

const Separator = styled.div`
  background: ${LIGHT_GREY_COLOR};
  width: 1px;
  height: 16px;
`

const RoundedButton = styled(RoundedButtonBase)`
  color: ${DARK_BLUE_COLOR};
  font-size: ${PX14};
`
const AddButton = styled(RoundedButton)``

const TileLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(264px, 1fr));
  width: 100%;
`

const ScrollAreaRoot = styled(ScrollArea.Root)`
  width: 100%;
`

const ScrollWrapper = styled.div`
  position: relative;
  flex: 1;
  overflow: hidden;
  display: flex;
`

const MigrationModalWrapper = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 32px;
  background: #000000bf;
`

const MigrationModal = styled.div`
  max-width: 660px;

  ${MODAL_BOX_SHADOW}
  border-radius: 16px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 32px;
`

const getSort = (index: number, length: number) => {
  return length - index
}

type Props = {
  id: number
  grades: GetAllGradesResult
  onClose: () => void
  setIsNewPlaylistOpen: Dispatch<SetStateAction<boolean>>
  setIsAddLessonOpen: Dispatch<SetStateAction<boolean>>
  setMigrationModalVisibe: Dispatch<SetStateAction<boolean>>
  isSharedWithMe?: boolean
  addedByUser?: UserPlaylistTypeOwner
}

type UserPlaylistItems = NonNullable<
  NonNullable<GetUserListResult>["playlist"]
>["lessons"]
type UserPlaylistItem = UserPlaylistItems[number]

const PlaylistModalContentInner = ({
  id,
  grades,
  onClose,
  setIsNewPlaylistOpen,
  setIsAddLessonOpen,
  setMigrationModalVisibe,
  isSharedWithMe,
  addedByUser,
  userList,
}: Props & { userList: NonNullable<GetUserListResult> }) => {
  const router = useRouter()
  const currentSchool = useCurrentOrganization()
  const [deletePlaylistItemMutation] = useMutation(deleteUserListItem)
  const [updateUserPlaylistOrderMutation] = useMutation(updateUserPlaylistOrder)
  const [updateUserPlaylistMutation] = useMutation(updateUserPlaylist)
  const [updateUserPlaylistDisplayTypeMutation] = useMutation(
    updateUserPlaylistDisplayType
  )
  const [batchDeletePlaylistMutation] = useMutation(batchDeleteUserPlaylist)
  const [deletePlaylistMutation] = useMutation(deleteUserPlaylist)
  const [openShareModalLesson, setOpenShareModalLesson] = useState<null | {
    id: number
    displayTitle: string | null
  }>(null)
  const [{ canEdit, canDelete }] = useQuery(getCanWriteUserPlaylist, {
    playlistId: id,
    type: "items",
  })
  const [isAddLessonToOtherPlaylistOpen, setIsAddLessonToOtherPlaylistOpen] =
    useState(false)
  const [vakken, setVakken] = useState<ContentSubjectType[]>([])
  const [activeGrades, setActiveGrades] = useState<number[]>([])
  const addToast = useToast()
  const [currentDeleteItemId, setCurrentDeleteItemId] = useState<number | null>(
    null
  )
  const [isDuplicatePlaylistVisible, setDuplicatePlaylistVisible] =
    useState(false)
  // const [view, setView] = useState<"list" | "tile">("list")
  const [isEditMode, setEditMode] = useState(false)
  const [addToPlaylistLesson, setAddingToPlaylistLesson] =
    useState<AddToPlaylistLesson | null>(null)
  const view = userList.displayType[0]?.displayType || "LIST"
  const currentGroup = useCurrentGroup()
  const [currentUserRole] = useQuery(getOrganizatorRole, {})

  const isPlaying = useAtomValue(isPlayingAtom)
  const [displayState, setDisplayState] = useAtom(displayStateAtom)

  const { set, getUrl, pause } = usePlay()
  const [list, setList] = useState<UserPlaylistItems>([])

  const canOrderItem = !!(
    canEdit &&
    userList.type === "PLAYLIST" &&
    userList.sortItemsBy === "CUSTOM" &&
    vakken.length === 0 &&
    activeGrades.length === 0
  )

  const sortItems = useSortLessons()

  // Fill in list with correct order
  useEffect(() => {
    if (userList.type === "THEME") {
      setList(
        sortItems(
          userList.themeGroup?.globalPlaylist?.playlist?.lessons,
          userList.sortItemsBy
        ) || []
      )
      return
    }
    if (userList.type === "LL") {
      // setList([])
      return
    }
    setList(sortItems(userList.playlist?.lessons, userList.sortItemsBy) || [])
    return
  }, [userList, sortItems])

  useEffect(() => {
    if (!isEditMode) return
    if (isPlaying) pause()
    if (displayState !== "closed") setDisplayState("hidden")
  }, [displayState, isEditMode, isPlaying, pause, setDisplayState])

  // Set playlist for playback
  /*useEffect(() => {
    setPlaylist(list)
  }, [list, setPlaylist])*/

  const hasReachedMax =
    userList.accessLevel === "KIDS" && list.length >= MAX_KIDS_LIST
  const hasAddButton =
    userList &&
    getListTypeCanAdd(userList.accessLevel, currentUserRole || undefined) &&
    !isSharedWithMe

  const addButtonProps = {
    disabled: hasReachedMax,
    "aria-disabled": hasReachedMax ? "true" : "false",
    left: <TbPlus size={20} />,
    onClick: () => setIsAddLessonOpen(true),
  } as const

  const color = userList.playlist?.color || GREEN_COLOR

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
    useSensor(TouchSensor)
  )

  const updatePosition = useCallback(
    (oldIndex: number, newIndex: number) => {
      const newList = arrayMove(list, oldIndex, newIndex)
      setList(newList)
      updateUserPlaylistOrderMutation({
        id: userList.id,
        items: newList.map((item, index) => ({
          id: item.id,
          sort: getSort(index, newList.length),
        })),
      })
    },
    [list, updateUserPlaylistOrderMutation, userList.id]
  )

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event

    if (active && over && active.id !== over.id) {
      const oldIndex = list.findIndex((item) => item.id === active.id)
      const newIndex = list.findIndex((item) => item.id === over.id)
      updatePosition(oldIndex, newIndex)
    }
  }

  const filteredList = useMemo(() => {
    if (vakken.length === 0 && activeGrades.length === 0) {
      return list
    }
    return list.filter((item) => {
      return (
        (vakken.length === 0 || vakken.includes(item.lesson.contentSubject)) &&
        (activeGrades.length === 0 ||
          item.lesson.grades.filter((grade) => activeGrades.includes(grade.id))
            .length === activeGrades.length)
      )
    })
  }, [activeGrades, list, vakken])

  const enabledGrades = useMemo(() => {
    const set = new Set<number>()
    filteredList.forEach((item) => {
      item.lesson.grades.forEach((grade) => {
        set.add(grade.id)
      })
    })
    return Array.from(set)
  }, [filteredList])

  const isMigrationVisible = useMemo(() => {
    return getIsMigrationVisible(userList, currentGroup)
  }, [userList, currentGroup])

  const [multiSelect, setMultiSelect] = useAtom(multiSelectAtom)

  const selectedLessons = useMemo(() => {
    return list
      .filter((item) => multiSelect.includes(item.id))
      .map((item) => item.lesson)
  }, [list, multiSelect])

  const getItemContextMenu = (item: UserPlaylistItem) => {
    const _canEdit = userList.type === "PLAYLIST" && canEdit
    const url = getUrl(item.lesson)
    return _canEdit || url || isSharedWithMe ? (
      <>
        <DropdownMenu.Item asChild>
          <ContextMenu.Item
            onClick={() => {
              router.push(
                Routes.LessonPage({
                  lesson: item.lesson.slug,
                })
              )
            }}
          >
            <Group gap="5px" align="center">
              <TbArrowRight size={20} />
              <Value name="apps.web.src.playlists.components.playlistmodalcontent.open_les">
                Open les
              </Value>
            </Group>
          </ContextMenu.Item>
        </DropdownMenu.Item>
        {url ? (
          <DropdownMenu.Item asChild>
            <ContextMenu.Item
              onClick={() => {
                set(item)
              }}
            >
              <Group gap="5px" align="center">
                <TbPlayerPlay size={20} />
                <Value name="apps.web.src.playlists.components.playlistmodalcontent.lied_afspelen">
                  Speel lied in radioplayer
                </Value>
              </Group>
            </ContextMenu.Item>
          </DropdownMenu.Item>
        ) : null}
        <ContextMenu.Item
          onClick={async () => {
            setAddingToPlaylistLesson(item.lesson)
          }}
        >
          <Group gap="5px" align="center">
            <TbPlus size={20} />
            <Value
              name="apps.web.src.playlists.components.playlistmodalcontent.voeg_toe_aan_lijst"
              version="99"
            >
              Opslaan
            </Value>
          </Group>
        </ContextMenu.Item>
        {_canEdit ? (
          <DropdownMenu.Item asChild>
            <ContextMenu.Item
              onClick={(e) => {
                // e.preventDefault()
                setCurrentDeleteItemId(item.id)
              }}
              type="critical"
            >
              <Group gap="5px" align="center">
                <TbTrash size={20} />
                <Value name="apps.web.src.playlists.components.playlistmodalcontent.les_verwijderen">
                  Verwijder uit lijst
                </Value>
              </Group>
            </ContextMenu.Item>
          </DropdownMenu.Item>
        ) : null}
        {/* <ContextMenu.Item
          onClick={async () => {
            setEditMode((e) => !e)
          }}
        >
          <Group gap="5px" align="center">
            <TbArrowsMove size={20} />
            {isEditMode ? (
              <Value name="apps.web.src.playlists.components.playlistmodalcontent.stop_verplaatsen">
                Stop verplaatsen
              </Value>
            ) : (
              <Value name="apps.web.src.playlists.components.playlistmodalcontent.verplaatsen">
                Verplaatsen
              </Value>
            )}
          </Group>
        </ContextMenu.Item> */}
        {currentSchool ? (
          <DropdownMenu.Item asChild>
            <ContextMenu.Item
              onClick={() => {
                setOpenShareModalLesson(item.lesson)
              }}
            >
              <Group gap="5px" align="center">
                <TbShare size={20} />
                <Value name="apps.web.src.playlists.components.playlistmodalcontent.lied_delen">
                  Delen
                </Value>
              </Group>
            </ContextMenu.Item>
          </DropdownMenu.Item>
        ) : null}
      </>
    ) : null
  }

  return (
    <Root>
      <PlaylistModalHeader
        canEdit={canEdit}
        canDelete={canDelete}
        userPlaylist={userList}
        list={list}
        setIsNewPlaylistOpen={setIsNewPlaylistOpen}
        isSharedWithMe={isSharedWithMe}
        addedByUser={addedByUser || userList.owner}
        setIsAddLessonOpen={setIsAddLessonOpen}
        addButton={
          hasAddButton && (
            <AddButton {...addButtonProps}>
              <Value name="playlists.header.add" version="112">
                Voeg les toe
              </Value>
              {hasReachedMax ? (
                <Value
                  name="apps.web.src.playlists.components.playlistmodalcontent.max_van_max_kids_list_bereikt"
                  format={{ MAX_KIDS_LIST }}
                >
                  {`(Max van {MAX_KIDS_LIST} bereikt)`}
                </Value>
              ) : null}
            </AddButton>
          )
        }
        video={
          list.length > 0 ? (
            <Stack style={{ width: 240, marginRight: 15 }}>
              <video
                src={
                  userList.accessLevel === "KIDS"
                    ? "https://e06d971082994868aa2ed86d8d5fbedf.objectstore.eu/123zing/production/static_assets/new_platform/uitleg-kidspagina.mp4?v=1652269774"
                    : "https://e06d971082994868aa2ed86d8d5fbedf.objectstore.eu/123zing/production/static_assets/new_platform/uitleg-bibliotheek.mp4?v=1652269773"
                }
                controls
              />
            </Stack>
          ) : null
        }
      />
      {!(userList.type === "LL") && list.length > 0 && (
        <Filter>
          <Group gap={"8px"} justify="center" align="center">
            <Text bold>
              <Value name="apps.web.src.playlists.components.playlistmodalcontent.filters">
                Filters:
              </Value>
            </Text>
            <Group gap={"4px"} justify="center" align="center">
              <ToggleButtons
                {...{
                  vakken,
                  setVakken,
                  subscription: currentSchool?.subscription,
                }}
                size="small"
              />
            </Group>
            <Group gap={"4px"} justify="center" align="center">
              <Separator />
              <Grades
                grades={grades}
                activeGrades={activeGrades}
                enabledGrades={enabledGrades}
                onChange={(grade) => {
                  setActiveGrades(toggleArray(grade.id))
                }}
              />
            </Group>
            <Separator />
            <Group>
              <Text>
                {filteredList.length}{" "}
                <Value name="apps.web.src.playlists.components.playlistmodalcontent.items">
                  items
                </Value>
              </Text>
            </Group>
          </Group>
          <Group gap="12px">
            {canEdit ? (
              <>
                {" "}
                <TextButton onClick={() => setEditMode((e) => !e)}>
                  {isEditMode ? (
                    <>
                      <Value name="apps.web.src.playlists.components.playlistmodalcontent.stop_aanpassen">
                        Stop aanpassen
                      </Value>
                      <FaTimes />
                    </>
                  ) : (
                    <>
                      <Value name="apps.web.src.playlists.components.playlistmodalcontent.aanpassen">
                        Aanpassen
                      </Value>
                      <TbEdit />
                    </>
                  )}
                </TextButton>
                <Separator />
                <ContextMenu
                  trigger={
                    <TextButton>
                      {SORT_OPTIONS_DISPLAY[userList.sortItemsBy]}{" "}
                      <FaCaretDown />
                    </TextButton>
                  }
                  items={
                    <>
                      {SORT_OPTIONS.map((option) => (
                        <DropdownMenu.Item
                          asChild
                          onSelect={async () => {
                            await updateUserPlaylistMutation({
                              id: userList.id,
                              sortItemsBy: option,
                            })
                            await invalidateQuery(getUserList, {
                              id: userList.id,
                            })
                          }}
                        >
                          <ContextMenu.Item
                            isActive={option === userList.sortItemsBy}
                          >
                            {SORT_OPTIONS_DISPLAY[option]}
                          </ContextMenu.Item>
                        </DropdownMenu.Item>
                      ))}
                    </>
                  }
                />
                <Separator />
              </>
            ) : userList.accessLevel === "GLOBAL" ? (
              <>
                <PlaylistSortField
                  sort={userList.sortItemsBy}
                  onSort={async (option) => {
                    await updateUserPlaylistMutation({
                      id: userList.id,
                      sortItemsBy: option,
                    })
                    await invalidateQuery(getUserList, {
                      id: userList.id,
                    })
                  }}
                />
                <Separator />
              </>
            ) : null}{" "}
            <TextButton
              onClick={async () => {
                // setView((prevState) => (prevState === "tile" ? "list" : "tile"))
                await updateUserPlaylistDisplayTypeMutation({
                  id: userList.id,
                  displayType: view === "LIST" ? "GRID" : "LIST",
                })
                await invalidateQuery(getUserList, {
                  id: userList.id,
                })
              }}
            >
              {view === "LIST" ? (
                <TbLayoutGrid color={ORANGE_COLOR} size={20} />
              ) : (
                <TbLayoutList color={ORANGE_COLOR} size={20} />
              )}
            </TextButton>
          </Group>
        </Filter>
      )}
      <ScrollWrapper>
        <ScrollAreaRoot type="auto">
          <ScrollArea.Viewport>
            <Content>
              <Stack gap="8px">
                {userList.type === "LL" ? (
                  userList.learningLine?.planningLessonGroups.map((group) => {
                    return (
                      <ContentPadding>
                        <Stack gap="8px">
                          <Title size={PX28}>{group.displayTitle}</Title>
                          <Text>
                            <RichText body={group.description} />
                          </Text>
                          <Hr />
                          {group.lessons.map((item, index) => (
                            <PlaylistItem
                              key={item.id.toString()}
                              color={color}
                              {...Routes.LessonPage({
                                lesson: item.lesson.slug,
                              })}
                              canOrderItem={false}
                              isEditMode={false}
                              item={item}
                              index={index}
                              lessons={group.lessons}
                              grades={grades}
                              updatePosition={updatePosition}
                              contextMenuItems={
                                getUrl(item.lesson) ? (
                                  <DropdownMenu.Item asChild>
                                    <ContextMenu.Item
                                      onClick={() => {
                                        set(item)
                                      }}
                                    >
                                      <Value
                                        name="apps.web.src.playlists.components.playlistmodalcontent.lied_afspelen"
                                        version="1"
                                      >
                                        Speel lied in radioplayer
                                      </Value>
                                    </ContextMenu.Item>
                                  </DropdownMenu.Item>
                                ) : null
                              }
                            />
                          ))}
                        </Stack>
                      </ContentPadding>
                    )
                  })
                ) : list.length > 0 ? (
                  <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragEnd={handleDragEnd}
                  >
                    <UserListPadding>
                      {view === "LIST" ? (
                        <>
                          <SortableContext
                            items={filteredList}
                            strategy={verticalListSortingStrategy}
                          >
                            {filteredList.map((item, index) => {
                              return (
                                <PlaylistItem
                                  color={color}
                                  key={item.id.toString()}
                                  {...Routes.LessonPage({
                                    lesson: item.lesson.slug,
                                  })}
                                  canOrderItem={canOrderItem}
                                  isEditMode={isEditMode}
                                  item={item}
                                  index={index}
                                  lessons={list}
                                  grades={grades}
                                  updatePosition={updatePosition}
                                  contextMenuItems={getItemContextMenu(item)}
                                />
                              )
                            })}
                          </SortableContext>
                        </>
                      ) : (
                        <SortableContext
                          items={filteredList}
                          strategy={rectSortingStrategy}
                        >
                          <TileLayout>
                            {filteredList.map((item) => {
                              return (
                                <PlaylistTileLesson
                                  key={item.id.toString()}
                                  item={item}
                                  grades={grades}
                                  isEditMode={isEditMode}
                                  canOrderItem={canOrderItem}
                                  contextMenuItems={getItemContextMenu(item)}
                                />
                              )
                            })}
                          </TileLayout>
                        </SortableContext>
                      )}
                    </UserListPadding>
                  </DndContext>
                ) : (
                  <PlaylistModalEmptyState
                    userList={userList}
                    addButton={
                      hasAddButton && (
                        <AddButton {...addButtonProps}>
                          <Value name="kids.playlists.header.add">
                            Voeg liedjes en lessen toe aan de Kidslijst
                          </Value>
                          {hasReachedMax ? (
                            <Value
                              name="apps.web.src.playlists.components.playlistmodalcontent.max_van_max_kids_list_bereikt"
                              format={{ MAX_KIDS_LIST }}
                            >
                              {`(Max van {MAX_KIDS_LIST} bereikt)`}
                            </Value>
                          ) : null}
                        </AddButton>
                      )
                    }
                  />
                )}
                <AlertDialog.Root
                  open={!!currentDeleteItemId}
                  onOpenChange={() => setCurrentDeleteItemId(null)}
                >
                  <AlertModal
                    title={
                      <Stack gap="24px">
                        <Modal.Title>
                          <Value name="playlists.item.verwijderen.titel">
                            Les uit afspeellijst verwijderen?
                          </Value>
                        </Modal.Title>
                        <Text>
                          <Value name="apps.web.src.playlists.components.playlistmodalcontent.je_kunt_lessen_altijd_terugzetten_in_een_afspeellijst">
                            Je kunt lessen altijd terugzetten in een
                            afspeellijst.
                          </Value>
                        </Text>
                      </Stack>
                    }
                    cancel={
                      <Modal.CancelButton>
                        <Value name="playlists.item.verwijderen.annuleren">
                          Nee, annuleer
                        </Value>
                      </Modal.CancelButton>
                    }
                    submit={
                      <Modal.SubmitButton
                        onClick={async () => {
                          if (!currentDeleteItemId) return
                          await deletePlaylistItemMutation({
                            id: currentDeleteItemId,
                          })
                          await invalidateQuery(getUserList, {
                            id: userList.id,
                          })
                          await invalidateQuery(getBulletinBoardItems)
                          addToast({
                            content: (
                              <Value name="playlists.item.verwijderen.success">
                                Les uit afspeellijst verwijderd.
                              </Value>
                            ),
                          })
                        }}
                      >
                        <Value name="playlists.item.verwijderen.bevestigen">
                          Ja, verwijder
                        </Value>
                      </Modal.SubmitButton>
                    }
                  />
                </AlertDialog.Root>

                <Dialog.Root
                  open={isDuplicatePlaylistVisible}
                  onOpenChange={() => setDuplicatePlaylistVisible(false)}
                >
                  {isDuplicatePlaylistVisible ? (
                    <DuplicatePlaylistModal
                      key={`duplicate-${isDuplicatePlaylistVisible}`}
                      userList={userList}
                      onComplete={() => setDuplicatePlaylistVisible(false)}
                    />
                  ) : null}
                </Dialog.Root>
              </Stack>
            </Content>
          </ScrollArea.Viewport>
          <ScrollArea.Scrollbar>
            <ScrollArea.Thumb />
          </ScrollArea.Scrollbar>
        </ScrollAreaRoot>
        {openShareModalLesson ? (
          <ShareModal.Controlled
            type="LESSON"
            lesson={openShareModalLesson}
            isOpen={true}
            onClose={() => setOpenShareModalLesson(null)}
          />
        ) : null}
        <AddLessonToListModalUncontrolled
          isOpen={isAddLessonToOtherPlaylistOpen}
          setOpen={setIsAddLessonToOtherPlaylistOpen}
          lessons={selectedLessons}
        />
        <AnimatePresence>
          {isEditMode ? (
            <PlaylistBatchUpdateModal
              key={`batch-update-${id}`}
              selectedLessons={selectedLessons}
              onDelete={async () => {
                await batchDeletePlaylistMutation({
                  id,
                  items: multiSelect,
                })
                await invalidateQuery(getUserList, {
                  id,
                })
                setMultiSelect([])
                await invalidateQuery(getBulletinBoardItems)
                addToast({
                  content: (
                    <Value name="playlists.item.verwijderen.success">
                      Items zijn verwijderd
                    </Value>
                  ),
                })
              }}
            />
          ) : null}
        </AnimatePresence>
      </ScrollWrapper>
      {isMigrationVisible ? (
        <MigrationModalWrapper>
          <MigrationModal>
            <Stack gap="16px" align="stretch">
              <Group justify="flex-start" gap="12px">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="37.004"
                  height="34"
                  viewBox="0 0 37.004 34"
                >
                  <g
                    id="Group_71018"
                    data-name="Group 71018"
                    transform="translate(0 0)"
                  >
                    <path
                      id="Path_12421"
                      data-name="Path 12421"
                      d="M1.923,11.357c-4.431,6.9.184,14.713.184,14.713S6.022,34.639,18.7,33.208c10.068-1.137,19.445-5.587,17.461-18.865C34.65,4.243,21.937,2.526,18.6,3.22a.466.466,0,0,1-.452-.15A10.163,10.163,0,0,0,12.631.011a.462.462,0,0,0-.473.733C13.377,2.053,14.587,4.469,10.1,5.8a13.656,13.656,0,0,0-8.175,5.557"
                      transform="matrix(-1, 0.017, -0.017, -1, 37.004, 33.364)"
                      fill="#03004e"
                    />
                    <path
                      id="Path_20478"
                      data-name="Path 20478"
                      d="M5.478,16.787,4.906,6.358H.726L.154,16.787ZM2.816,0A2.973,2.973,0,0,0,.77.682,2.2,2.2,0,0,0,0,2.4,2.252,2.252,0,0,0,.77,4.147a2.943,2.943,0,0,0,2.046.693A2.911,2.911,0,0,0,4.84,4.147,2.252,2.252,0,0,0,5.61,2.4,2.2,2.2,0,0,0,4.84.682,2.94,2.94,0,0,0,2.816,0Z"
                      transform="translate(22.548 23.257) rotate(172)"
                      fill="#fff"
                    />
                  </g>
                </svg>
                <Title size={PX24}>
                  <Value name="apps.web.src.playlists.components.playlistmodalcontent.migrate.title">
                    Voor welke groep is deze lijst?
                  </Value>
                </Title>
              </Group>
              <Text>
                <Value
                  name="apps.web.src.playlists.components.playlistmodalcontent.migrate.body"
                  version="1"
                >
                  {`Geef aan voor welke groep(en) deze lijst zichtbaar moet zijn.
                  Alle collega's die gekoppeld zijn aan de betreffende groep,
                  zien de lijst in hun bibliotheek.`}
                </Value>
              </Text>
              <Group gap="8px" justify="flex-end">
                <RoundedButtonBase
                  onClick={async () => {
                    await deletePlaylistMutation({ id: userList.id })
                    await invalidateQuery(getGroupedUserPlaylists)
                    await invalidateQuery(getUserList)

                    addToast({
                      content: (
                        <Value name="playlists.verwijderen.success">
                          Afspeellijst verwijderd
                        </Value>
                      ),
                    })
                  }}
                >
                  <Value
                    name="apps.web.src.playlists.components.playlistmodalcontent.verwijder_lijst"
                    version="1"
                  >
                    Verwijder lijst uit bibliotheek
                  </Value>
                </RoundedButtonBase>
                <RoundedButtonBase
                  variant="filled"
                  onClick={() => setMigrationModalVisibe(true)}
                >
                  <Value name="apps.web.src.playlists.components.playlistmodalcontent.selecteer_groepen">
                    Selecteer groepen
                  </Value>
                </RoundedButtonBase>
              </Group>
            </Stack>
          </MigrationModal>
        </MigrationModalWrapper>
      ) : null}

      {addToPlaylistLesson && (
        <AddLessonToListModalUncontrolled
          isOpen={!!addToPlaylistLesson}
          setOpen={(val) =>
            setAddingToPlaylistLesson(val ? addToPlaylistLesson : null)
          }
          lessons={[addToPlaylistLesson]}
        />
      )}
      {/*<UserListPlayback />*/}
    </Root>
  )
}

export const PlaylistModalContent = (props: Props) => {
  const [userList] = useQuery(getUserList, { id: props.id })
  if (!userList) return null
  return <PlaylistModalContentInner {...props} userList={userList} />
}
